import dropdownToObject from '@arrive/utils/dropdown-to-obj';

// Theme
export const THEME_SET = 'THEME_SET';
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

// Auth
export const AUTH_SIGN_IN_REQUEST = 'AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_UP_REQUEST = 'AUTH_SIGN_UP_REQUEST';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';

export const ARRIVE_OUTDOORS_PARTNER_ID = 1;

export const ORDER_STATUS_LIST = [
  {
    value: 'shipped',
    label: 'Shipped'
  },
  {
    value: 'paymentProcessed',
    label: 'Payment Processed'
  },
  {
    value: 'refunded',
    label: 'Refunded'
  },
  {
    value: 'placed',
    label: 'Placed'
  },
  {
    value: 'cancelled',
    label: 'Cancelled'
  },
  {
    value: 'delivered',
    label: 'Delivered'
  },
  {
    value: 'received',
    label: 'Received'
  }
];

export const ORDER_PAYMENT_STATUS_LIST = [
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'cancelled',
    label: 'Cancelled'
  },
  {
    value: 'captured',
    label: 'Captured'
  },
  {
    value: 'partialRefund',
    label: 'Partial Refund'
  },
  {
    value: 'fullRefund',
    label: 'Full Refund'
  },
  {
    value: 'declined',
    label: 'Declined'
  },
  {
    value: 'refundDeclined',
    label: 'Refund Declined'
  },
  {
    value: 'processing',
    label: 'Processing'
  },
  {
    value: 'processingRefund',
    label: 'Processing Refund'
  }
];

export const ORDER_TYPES = [
  {
    value: 'rental',
    label: 'Rental'
  },
  {
    value: 'resale',
    label: 'Resale'
  }
];

export const PRODUCT_CATEGORY_LIST = [
  { value: 1, label: 'Accessories' },
  { value: 2, label: 'Apparel' },
  { value: 3, label: 'Backpacks' },
  { value: 4, label: 'Chair' },
  { value: 5, label: 'Cooler' },
  { value: 6, label: 'Electronics' },
  { value: 7, label: 'Equipment' },
  { value: 8, label: 'Eyewear' },
  { value: 9, label: 'Footwear' },
  { value: 10, label: 'Gloves' },
  { value: 11, label: 'Kitchen' },
  { value: 12, label: 'Lighting' },
  { value: 13, label: 'SleepPad' },
  { value: 14, label: 'SleepingBag' },
  { value: 15, label: 'Table' },
  { value: 16, label: 'Tent' },
  { value: 17, label: 'TennisRacket' },
  { value: 18, label: 'Golf' },
  { value: 19, label: 'Set' }
];

export const ADDRESS_TYPES = [
  {
    value: 'home',
    label: 'Home'
  },
  {
    value: 'business',
    label: 'Business'
  },
  {
    value: 'airbnbHotel',
    label: 'Airbnb/Hotel'
  },
  {
    value: 'fedexLocation',
    label: 'Fedex Location'
  }
];

export const ADDRESS_TYPES_MAP = dropdownToObject(ADDRESS_TYPES);

export const COLLECTION_GROUPS = [
  { value: 'campHike', label: 'Camp & Hike' },
  { value: 'skiSnow', label: 'Ski & Snow' }
];

export const COLLECTION_GROUP_MAP = dropdownToObject(COLLECTION_GROUPS);

export const PRODUCT_TYPES = [
  { value: 0, label: 'Product' },
  { value: 1, label: 'Set' }
];

interface CloudinaryConfig {
  cloudName: string;
  secure: boolean;
  fetchFormat: string;
}

const config: CloudinaryConfig = {
  cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME || 'arrive-outdoors',
  secure: true,
  fetchFormat: 'auto'
};

export default config;

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { darken, rgba } from 'polished';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteChildType, RouteType } from '../types/routes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../vendor/perfect-scrollbar.css';
import { Image } from 'cloudinary-react';

import {
  Avatar,
  Badge,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  FormControl,
  Grid,
  InputLabel,
  List as MuiList,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { sidebarRoutes as routes } from '@arrive/routes/index';

import { useAuth0 } from '@auth0/auth0-react';
import { AdminContext } from '@arrive/contexts/AdminContext';
import { Partner } from '@arrive/types/partner';
import PartnerService from '@arrive/services/partnerService';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  padding-top: ${(props) => props.theme.spacing(5)}px;
  padding-bottom: ${(props) => props.theme.spacing(5)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;

  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) => darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};

  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }

  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;

  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const PartnerSelectForm = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(1)}px;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
`;

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsable: boolean;
  badge?: string | number;
  activeClassName?: string;
  button: true;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => (
  <Category {...rest}>
    {icon}
    <CategoryText>{name}</CategoryText>
    {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
    {badge ? <CategoryBadge label={badge} /> : ''}
  </Category>
);

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({ name, to, badge }) => (
  <Link button dense component={NavLink} exact to={to} activeClassName="active">
    <LinkText>{name}</LinkText>
    {badge ? <LinkBadge label={badge} /> : ''}
  </Link>
);

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  ...rest
}) => {
  const { user, getAccessTokenSilently } = useAuth0();

  const { partner, changePartner } = useContext(AdminContext);

  const [partners, setPartners] = useState<Partner[]>();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const partnerService = new PartnerService(token);
      const pars = await partnerService.GetPartners();
      setPartners(pars);
    })();
  }, [setPartners, getAccessTokenSilently]);

  useEffect(() => {
    if (!partner && partners && partners.length) {
      changePartner(partners.find((p) => p.id === 1) as Partner);
    }
  }, [partner, partners, changePartner]);

  const handlePartnerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newPartner = partners?.find((p) => p.id === event.target.value) ?? {
      id: 1
    };
    changePartner(newPartner);
  };

  type tplotOptions = {
    [key: number]: boolean;
  };
  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route: RouteType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/';

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        {partner && partner.logo ? (
          <Image publicId={partner.logo.publicId} width={128} />
        ) : (
          <Image publicId="partnership-landing/arrive-logo-partner-landing" width={128} />
        )}{' '}
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {partners && (
              <PartnerSelectForm variant="outlined">
                <InputLabel id="global-partner-select-label">Partner</InputLabel>
                <Select
                  labelId="global-partner-select-label"
                  id="global-partner-select"
                  value={partner?.id || ''}
                  onChange={handlePartnerChange}
                  label="Partner">
                  {partners.map((p) => (
                    <MenuItem value={p.id} key={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </PartnerSelectForm>
            )}

            {routes.map((category: RouteType, index) => (
              <React.Fragment key={index}>
                {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />

                    <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                      {category.children.map((route: RouteChildType, index: number) => (
                        <SidebarLink
                          key={index}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SidebarFooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              variant="dot">
              {user && user.picture && <Avatar alt="Lucy Lavender" src={user.picture} />}
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">{user?.name}</SidebarFooterText>
            {/*<SidebarFooterSubText variant="caption">*/}
            {/*  {user.role}*/}
            {/*</SidebarFooterSubText>*/}
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);

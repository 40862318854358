const errorMessage = (err: any, name: string) => {
  if (err.response.data) {
    const isString = typeof err.response.data === 'string';
    if (isString) {
      return err.response.data;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (err.response.data.hasOwnProperty('title')) {
      return err.response.data.title;
      // eslint-disable-next-line no-prototype-builtins
    } else if (err.response.data.hasOwnProperty('isValid')) {
      console.debug(err);
      return `${name} is not Valid`;
    }
    console.debug(err);
    return `${name} failed`;
  }
  console.debug(err);
  return `${name} failed`;
};

export default class exceptionHandler {
  static errorMessage(err: any, name: string) {
    return errorMessage(err, name);
  }
}

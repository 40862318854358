import axios from 'axios';
import exceptionHandler from '@arrive/helper/exceptionHandler';
import { Category, ProductCollection } from '@arrive/types/collection';
import { Response } from '@arrive/types/response';

class CollectionService {
  constructor(token: string) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.baseURL = process.env.REACT_APP_COMMERCE_API_URL;
  }

  async getCategories(partnerId: number): Promise<Category[]> {
    const response = await axios({
      url: '/admin/collection/category',
      headers: { 'X-Partner-Id': partnerId }
    });
    return response?.data || [];
  }

  async getCollectionCategories(partnerId: number): Promise<ProductCollection[]> {
    const response = await axios({
      url: '/admin/collection',
      headers: { 'X-Partner-Id': partnerId }
    });
    return response?.data || [];
  }

  async createCategory(partnerId: number, category: Category): Promise<Response<Category>> {
    const response: Response<Category> = {
      errorMessage: ''
    };

    await axios({
      url: '/admin/collection/category',
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
        'X-Partner-Id': partnerId
      },
      data: category
    })
      .then((res) => {
        response.result = res.data;
        response.successMessage = 'Category created';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Category create');
      });

    return Promise.resolve(response);
  }

  async updateCategory(id: number, category: Category): Promise<Response<Category>> {
    const response: Response<Category> = {
      errorMessage: ''
    };

    await axios({
      url: `/admin/collection/category/${id}`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      },
      data: category
    })
      .then((res) => {
        response.result = res.data;
        response.successMessage = 'Category updated';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Category update');
      });

    return Promise.resolve(response);
  }

  async deleteCategory(id: number): Promise<Response<boolean>> {
    const response: Response<boolean> = {
      errorMessage: '',
      result: false
    };

    await axios({
      url: `/admin/collection/category/${id}`,
      method: 'DELETE'
    })
      .then(() => {
        response.result = true;
        response.successMessage = 'Category deleted';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Category delete');
      });

    return Promise.resolve(response);
  }

  async createProductCollection(
    partnerId: number,
    collection: ProductCollection
  ): Promise<Response<ProductCollection>> {
    const response: Response<ProductCollection> = {
      errorMessage: ''
    };

    await axios({
      url: '/admin/collection',
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
        'X-Partner-Id': partnerId
      },
      data: {
        ...collection,
        group: collection.group ? collection.group : null
      }
    })
      .then((res) => {
        response.result = res.data;
        response.successMessage = 'Product Collection created';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Product Collection create');
      });

    return Promise.resolve(response);
  }

  async updateCollection(
    id: number,
    collection: ProductCollection
  ): Promise<Response<ProductCollection>> {
    const response: Response<ProductCollection> = {
      errorMessage: ''
    };

    await axios({
      url: `/admin/collection/${id}`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      },
      data: {
        ...collection,
        group: collection.group ? collection.group : null
      }
    })
      .then((res) => {
        response.result = res.data;
        response.successMessage = 'ProductCollection updated';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'ProductCollection update');
      });

    return Promise.resolve(response);
  }

  async deleteProductCollection(id: number): Promise<Response<boolean>> {
    const response: Response<boolean> = {
      errorMessage: '',
      result: false
    };

    await axios({
      url: `/admin/collection/${id}`,
      method: 'DELETE'
    })
      .then(() => {
        response.result = true;
        response.successMessage = 'Product Collection deleted';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Product Collection delete');
      });

    return Promise.resolve(response);
  }

  async removeProductFromCollection(
    productId: number,
    collectionId: number
  ): Promise<Response<boolean>> {
    const response: Response<boolean> = {
      errorMessage: '',
      result: false
    };

    await axios({
      url: `/admin/collection/${collectionId}/product/${productId}`,
      method: 'DELETE'
    })
      .then(() => {
        response.result = true;
        response.successMessage = 'Product Collection deleted';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Product Collection delete');
      });

    return Promise.resolve(response);
  }

  async addProductToCollection(
    productId: number,
    collectionId: number
  ): Promise<Response<ProductCollection>> {
    const response: Response<ProductCollection> = {
      errorMessage: ''
    };

    await axios({
      url: `/admin/collection/${collectionId}/product/${productId}`,
      method: 'POST'
    })
      .then((res) => {
        response.result = res.data;
        response.successMessage = 'Product Collection Added';
      })
      .catch((err) => {
        response.errorMessage = exceptionHandler.errorMessage(err, 'Product Collection add');
      });

    return Promise.resolve(response);
  }
}

export default CollectionService;

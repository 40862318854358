const dropdownToObject = (array: { value: string; label: string }[]) =>
  array.reduce(
    (map: any, obj: { label: string; value: string }) => (
      // eslint-disable-next-line no-sequences
      (map[obj.value] = obj.label), map
    ),
    {}
  );

export default dropdownToObject;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import App from './App';
import './mocks';

import { Provider } from 'react-redux';
import store from './redux/store/index';
import { Auth0Provider } from '@auth0/auth0-react';
import auth0Config from './config/auth0Config';

ReactDOM.render(
  <Auth0Provider {...auth0Config}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);

import { Category, CategoryCollection, ProductCollection } from '@arrive/types/collection';
import { ProductDetailType } from '@arrive/types/product';
import { InitialStateType } from './context';

type Action =
  | {
      type: 'GET_COLLECTION_CATEGORIES_COMPLETE';
      payload: CategoryCollection[];
    }
  | { type: 'GET_COLLECTION_CATEGORIES_ERROR'; payload: any }
  | { type: 'CATEGORY_CREATED'; payload: Category }
  | { type: 'CATEGORY_UPDATED'; payload: Category }
  | { type: 'CATEGORY_DELETED'; payload: number }
  | { type: 'PRODUCT_COLLECTION_CREATED'; payload: ProductCollection }
  | {
      type: 'PRODUCT_COLLECTION_DELETED';
      payload: { collectionId: number; categoryId: number };
    }
  | { type: 'PRODUCT_COLLECTION_UPDATED'; payload: ProductCollection }
  | {
      type: 'REMOVED_PRODUCT_FROM_COLLECTION';
      payload: { productId: number; collectionId: number };
    }
  | { type: 'ADD_PRODUCT_TO_COLLECTION'; payload: ProductCollection };

const reducer = (state: InitialStateType, { type, payload }: Action) => {
  switch (type) {
    case 'GET_COLLECTION_CATEGORIES_COMPLETE':
      return { ...state, categoryCollections: payload };
    case 'GET_COLLECTION_CATEGORIES_ERROR':
      return {
        ...state,
        categoryCollections: []
      };
    case 'CATEGORY_CREATED':
      return {
        ...state,
        categoryCollections: [...state.categoryCollections, payload]
      };
    case 'CATEGORY_UPDATED':
      // eslint-disable-next-line no-case-declarations
      const categoryCollections = [...state.categoryCollections];
      // eslint-disable-next-line no-case-declarations
      const index = categoryCollections.findIndex(
        (val: CategoryCollection) => val.id === payload.id
      );
      categoryCollections[index] = {
        ...payload,
        productCollection: categoryCollections[index].productCollection
      };

      return {
        ...state,
        categoryCollections
      };
    case 'CATEGORY_DELETED':
      return {
        ...state,
        categoryCollections: state.categoryCollections.filter(
          (value: CategoryCollection) => value.id !== payload
        )
      };
    case 'PRODUCT_COLLECTION_CREATED':
      return {
        ...state,
        categoryCollections: state.categoryCollections.reduce(
          (accu: CategoryCollection[], current: CategoryCollection) => {
            const result = [...accu];

            if (current.id === (payload as ProductCollection).collectionCategory.id) {
              current.productCollection.push(payload);
            }

            return result;
          },
          state.categoryCollections
        )
      };
    case 'PRODUCT_COLLECTION_DELETED':
      return {
        ...state,
        categoryCollections: state.categoryCollections.reduce(
          (accu: CategoryCollection[], current: CategoryCollection) => {
            const result = [...accu];

            if (current.id === payload.categoryId) {
              current.productCollection = current.productCollection.filter(
                (val: ProductCollection) => val.id !== payload.collectionId
              );
            }

            return result;
          },
          state.categoryCollections
        )
      };
    case 'ADD_PRODUCT_TO_COLLECTION':
    case 'PRODUCT_COLLECTION_UPDATED':
      return {
        ...state,
        categoryCollections: state.categoryCollections.reduce(
          (accu: CategoryCollection[], current: CategoryCollection) => {
            const result = [...accu];

            if (current.id === payload.collectionCategory.id) {
              const index = current.productCollection.findIndex(
                (val: ProductCollection) => val.id === payload.id
              );

              if (~index) {
                current.productCollection[index] = payload;
              }
            }

            return result;
          },
          state.categoryCollections
        )
      };
    case 'REMOVED_PRODUCT_FROM_COLLECTION':
      return {
        ...state,
        categoryCollections: state.categoryCollections.reduce(
          (accu: CategoryCollection[], current: CategoryCollection) => {
            const result = [...accu];

            const collectionIndex = current.productCollection.findIndex(
              (val: ProductCollection) => val.id === payload.collectionId
            );

            if (~collectionIndex) {
              const productIndex = current.productCollection[collectionIndex].products.findIndex(
                (product: ProductDetailType) => product.id === payload.productId
              );
              if (~productIndex) {
                current.productCollection[collectionIndex].products.splice(productIndex, 1);
              }
            }

            return result;
          },
          state.categoryCollections
        )
      };
    default:
      return state;
  }
};

export default reducer;

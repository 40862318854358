import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import useSnackbar from '@arrive/components/Arrive/Snackbar/useSnackbar.hook';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { FC } from 'react';

type SnackbarsProps = {
  autoHideDuration?: number
}

const Snackbars: FC<SnackbarsProps> = ({ autoHideDuration = 3000 }) => {
  const { isOpen, handleClose: closeSnackbar, handleExited, snackMessage } = useSnackbar();

  const handleClose = (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar();
  };

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={snackMessage?.severity || 'info'} onClose={handleClose} elevation={6} variant="filled">
          {snackMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Snackbars;

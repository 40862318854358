import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SnackbarMessage, {
  SnackbarSeverity
} from '@arrive/components/Arrive/Snackbar/Snackbar.types';
import { Response } from '@arrive/types/response';

export interface SnackbarState {
  isOpen: boolean;
  snackMessage: SnackbarMessage | null;

  handleClose(): void;

  handleExited(): void;

  dispatchResponseMessage<T>(response: Response<T>): void;

  dispatchSuccess(message: string): void;

  dispatchInfo(message: string): void;

  dispatchWarning(message: string): void;

  dispatchError(message: string): void;
}

const SnackbarContext = createContext<SnackbarState | undefined>(undefined);

export const SnackbarProvider: FC = ({ children }) => {
  const [snacks, setSnacks] = useState<SnackbarMessage[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<SnackbarMessage | null>(null);

  useEffect(() => {
    if (snacks.length && !snackMessage) {
      // Set a new snack when we don't have an active one
      setSnackMessage({ ...snacks[0] });
      setSnacks((prev) => prev.slice(1));
      setOpen(true);
    } else if (snacks.length && snackMessage && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snacks, snackMessage]);

  const addSnack = (message: string, severity: SnackbarSeverity) => {
    const snack: SnackbarMessage = { message, severity, key: new Date().getTime() };
    setSnacks((prev: SnackbarMessage[]) => [...prev, snack]);
  };

  const dispatchSuccess = useCallback((message: string) => {
    addSnack(message, 'success');
  }, []);
  const dispatchInfo = useCallback((message: string) => {
    addSnack(message, 'info');
  }, []);
  const dispatchWarning = useCallback((message: string) => {
    addSnack(message, 'warning');
  }, []);
  const dispatchError = useCallback((message: string) => {
    addSnack(message, 'error');
  }, []);
  const dispatchResponseMessage = useCallback(
    (response: Response<any>) => {
      if (response?.successMessage) {
        dispatchSuccess(response.successMessage);
      }
      if (response?.errorMessage) {
        dispatchError(response.errorMessage);
      }
    },
    [dispatchError, dispatchSuccess]
  );
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setSnackMessage(null);
  }, []);

  const value = useMemo(
    () => ({
      snackMessage,
      isOpen,
      handleClose,
      handleExited,
      dispatchResponseMessage,
      dispatchSuccess,
      dispatchInfo,
      dispatchWarning,
      dispatchError
    }),
    [
      dispatchResponseMessage,
      dispatchError,
      dispatchInfo,
      dispatchSuccess,
      dispatchWarning,
      isOpen,
      handleClose,
      handleExited,
      snackMessage
    ]
  );

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

const useSnackbar = (): SnackbarState => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export default useSnackbar;

import async from '@arrive/components/Async';

import { Monitor, Package, ShoppingCart, Sun, Tag } from 'react-feather';

const Orders = async(() => import('@arrive/pages/pages/Orders'));
const OrderDetails = async(() => import('@arrive/pages/pages/OrderDetails'));
const Products = async(() => import('@arrive/pages/pages/Products'));
const ProductDetail = async(() => import('../pages/pages/ProductDetailContainer'));
const Categories = async(() => import('@arrive/pages/pages/CategoriesContainer'));
const ActivityTypes = async(() => import('@arrive/pages/pages/ActivityTypesContainer'));

// Landing
const Landing = async(() => import('@arrive/pages/presentation/Landing'));

const orderDetailsRoute = {
  id: 'OrderDetails',
  path: '/orders/:id',
  icon: <ShoppingCart />,
  component: OrderDetails,
  children: null
};

const orderRoute = {
  id: 'Orders',
  path: '/orders',
  icon: <ShoppingCart />,
  component: Orders,
  children: null
};

const productRoute = {
  id: 'Products',
  path: '/products',
  icon: <Package />,
  component: Products,
  children: null
};

const categoriesRoute = {
  id: 'Categories',
  path: '/categories',
  icon: <Tag />,
  component: Categories,
  children: null
};

const activityTypesRoute = {
  id: 'Activity Types',
  path: '/activity-types',
  icon: <Sun />,
  component: ActivityTypes,
  children: null
};

/*
const siteTextsRoute = {
  id: "Texts",
  path: "/site-texts",
  icon: <Type />,
  component: SiteTexts,
  children: null
};
*/

const productDetailRoute = {
  id: 'Product',
  path: '/products/:id',
  icon: <Package />,
  component: ProductDetail,
  children: null
};

const landingRoutes = {
  id: 'Landing Page',
  path: '/',
  header: 'Docs',
  icon: <Monitor />,
  component: Landing,
  children: null
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  orderRoute,
  orderDetailsRoute,
  productRoute,
  productDetailRoute,
  categoriesRoute,
  activityTypesRoute
  //siteTextsRoute
];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  orderRoute,
  productRoute,
  categoriesRoute,
  activityTypesRoute
  //siteTextsRoute,
];

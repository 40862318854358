import { createContext, Dispatch, FC, useCallback, useContext, useReducer } from 'react';
import {
  addProductToCollection as addProductToCollectionAction,
  createCategory as createCategoryAction,
  createCollection as createProductCollectionAction,
  deleteCategory as deleteCategoryAction,
  deleteCollection as deleteProductCollectionAction,
  getCollections as getCollectionsAction,
  removeProductFromCollection as removeProductFromCollectionAction,
  updateCategory as updateCategoryAction,
  updateCollection as updateProductCollectionAction
} from './actions';
import reducer from './reducer';
import {
  AddProductToCollection,
  Category,
  CategoryCollection,
  CreateCategory,
  CreateProductCollection,
  DeleteCategory,
  DeleteProductCollection,
  GetCollections,
  ProductCollection,
  RemoveProductFromCollection,
  UpdateCategory,
  UpdateProductCollection
} from '@arrive/types/collection';
import { Response } from '@arrive/types/response';

export type InitialStateType = {
  categoryCollections: CategoryCollection[];
};

const initialState = {
  categoryCollections: []
};

const CollectionContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

const CollectionContextProvider: FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { children } = props;
  const { Provider } = CollectionContext;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useCollectionContext = () => {
  const context = useContext<{
    state: InitialStateType;
    dispatch: Dispatch<any>;
  }>(CollectionContext);
  const { state, dispatch } = context;

  const getCollections = useCallback(
    async ({ partnerId, token }: GetCollections): Promise<void> =>
      getCollectionsAction({ dispatch, partnerId, token }),
    [dispatch]
  );

  const createCategory = useCallback(
    async ({ partnerId, token, category }: CreateCategory): Promise<Response<Category> | void> =>
      createCategoryAction({ dispatch, token, partnerId, category }),
    [dispatch]
  );

  const updateCategory = useCallback(
    async ({ id, category, token }: UpdateCategory): Promise<Response<Category> | void> =>
      updateCategoryAction({ dispatch, id, token, category }),
    [dispatch]
  );

  const deleteCategory = useCallback(
    async ({ id, token }: DeleteCategory): Promise<boolean> =>
      deleteCategoryAction({ dispatch, token, id }),
    [dispatch]
  );

  const createCollection = useCallback(
    async ({
      token,
      partnerId,
      collection
    }: CreateProductCollection): Promise<Response<ProductCollection> | void> =>
      createProductCollectionAction({
        collection,
        dispatch,
        partnerId,
        token
      }),
    [dispatch]
  );

  const updateCollection = useCallback(
    async ({
      collection,
      id,
      token
    }: UpdateProductCollection): Promise<Response<ProductCollection> | void> =>
      updateProductCollectionAction({ collection, dispatch, id, token }),
    [dispatch]
  );

  const deleteCollection = useCallback(
    async ({ categoryId, collectionId, token }: DeleteProductCollection) =>
      deleteProductCollectionAction({
        categoryId,
        collectionId,
        dispatch,
        token
      }),
    [dispatch]
  );

  const removeProductFromCollection = useCallback(
    async ({ collectionId, productId, token }: RemoveProductFromCollection): Promise<boolean> =>
      removeProductFromCollectionAction({
        collectionId,
        dispatch,
        productId,
        token
      }),
    [dispatch]
  );

  const addProductToCollection = useCallback(
    async ({
      collectionId,
      productId,
      token
    }: AddProductToCollection): Promise<Response<ProductCollection> | void> =>
      addProductToCollectionAction({
        collectionId,
        dispatch,
        productId,
        token
      }),
    [dispatch]
  );

  const { categoryCollections } = state;

  return {
    state: {
      categoryCollections
    },
    actions: {
      getCollections,
      createCategory,
      deleteCategory,
      updateCategory,
      createCollection,
      deleteCollection,
      updateCollection,
      removeProductFromCollection,
      addProductToCollection
    }
  };
};

export { CollectionContextProvider, useCollectionContext };

import { createContext, FC, useReducer } from 'react';
import { ActionTypes } from './actions';
import { Partner } from '@arrive/types/partner';

const defaultBulkOrderTotal = process.env?.REACT_APP_BULK_ORDERS
  ? +process.env?.REACT_APP_BULK_ORDERS
  : 1000;

export type AdminState = {
  partner?: Partner;
  defaultBulkOrderTotal: number;
  changePartner: (partner: Partner) => void;
};

const initialState: AdminState = {
  partner: sessionStorage.getItem('partner')
    ? JSON.parse(sessionStorage.getItem('partner') as string)
    : null,
  defaultBulkOrderTotal,
  changePartner: () => ({})
};

function reducer(state: AdminState, action: ActionTypes): AdminState {
  const newState = { ...state };
  switch (action.type) {
    case 'CHANGE_PARTNER':
      newState.partner = action.partner;
      return newState;
    default:
      return newState;
  }
}

export const AdminContext = createContext<AdminState>(initialState);

export const AdminStateProvider: FC = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changePartner = (partner: Partner) => {
    sessionStorage.setItem('partner', JSON.stringify(partner));
    dispatch({ type: 'CHANGE_PARTNER', partner });
  };

  const value = {
    ...state,
    defaultBulkOrderTotal,
    changePartner
  };
  return <AdminContext.Provider value={value}>{props.children}</AdminContext.Provider>;
};

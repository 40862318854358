import {
  AddProductToCollectionAction,
  Category,
  CategoryCollection,
  CreateCategoryAction,
  CreateProductCollectionAction,
  DeleteCategoryAction,
  DeleteProductCollectionAction,
  GetCollectionsAction,
  ProductCollection,
  RemoveProductFromCollectionAction,
  UpdateCategoryAction,
  UpdateProductCollectionAction
} from '@arrive/types/collection';
import CollectionService from '@arrive/services/collectionService';
import Alert from '@arrive/utils/alert';
import { Response } from '@arrive/types/response';

export const getCollections = async ({
  dispatch,
  token,
  partnerId
}: GetCollectionsAction): Promise<void> => {
  try {
    const collectionService = new CollectionService(token);
    const categories = (await collectionService.getCategories(partnerId)).map((item) => ({
      ...item,
      productCollection: []
    })) as CategoryCollection[];

    const collections = await collectionService.getCollectionCategories(partnerId);

    const payload = collections.reduce((accu: CategoryCollection[], current: ProductCollection) => {
      const result = [...accu];
      const { id } = current.collectionCategory;
      const index = result.findIndex((item: CategoryCollection) => item.id === id);

      if (~index) {
        result[index] = {
          ...result[index],
          productCollection: [...accu[index].productCollection, current]
        };
      }

      return result;
    }, categories);
    dispatch({ type: 'GET_COLLECTION_CATEGORIES_COMPLETE', payload });
  } catch {
    Alert.error('Error while trying to get Collections');
    dispatch({ type: 'GET_COLLECTION_CATEGORIES_ERROR' });
  }
};

export const createCategory = async ({
  dispatch,
  token,
  partnerId,
  category
}: CreateCategoryAction): Promise<Response<Category> | void> => {
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.createCategory(partnerId, category);
    if (data.result) {
      Alert.success(`${data.result.name} created`);
      dispatch({
        type: 'CATEGORY_CREATED',
        payload: { ...data.result, productCollection: [] }
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'CREATE_CATEGORY_ERROR' });
    }

    return data;
  } catch {
    Alert.error('Error while trying to create the category');
    dispatch({ type: 'CREATE_CATEGORY_ERROR' });
  }
};

export const updateCategory = async ({
  dispatch,
  token,
  id,
  category
}: UpdateCategoryAction): Promise<Response<Category> | void> => {
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.updateCategory(id, category);

    if (data.result) {
      Alert.success(`${data.result.name} updated`);
      dispatch({
        type: 'CATEGORY_UPDATED',
        payload: { ...data.result, productCollection: [] }
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'UPDATE_CATEGORY_ERROR' });
    }

    return data;
  } catch {
    Alert.error('Error while trying to update the category');
    dispatch({ type: 'UPDATE_CATEGORY_ERROR' });
  }
};

export const deleteCategory = async ({
  dispatch,
  token,
  id
}: DeleteCategoryAction): Promise<boolean> => {
  let result = false;
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.deleteCategory(id);

    if (data.result) {
      Alert.success('Category deleted');
      dispatch({
        type: 'CATEGORY_DELETED',
        payload: id
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'CATEGORY_DELETED_ERROR' });
    }

    result = !!data.result;
  } catch {
    Alert.error('Error while trying to delete the category');
    dispatch({ type: 'CATEGORY_DELETED_ERROR' });
  }

  return Promise.resolve(result);
};

export const createCollection = async ({
  dispatch,
  token,
  partnerId,
  collection
}: CreateProductCollectionAction): Promise<Response<ProductCollection> | void> => {
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.createProductCollection(partnerId, collection);

    if (data.result) {
      Alert.success(`${data.result.name} created`);
      dispatch({
        type: 'PRODUCT_COLLECTION_CREATED',
        payload: { ...data.result, productCollection: [] }
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'CREATE_PRODUCT_COLLECTION_ERROR' });
    }

    return data;
  } catch {
    Alert.error('Error while trying to create the collection');
    dispatch({ type: 'CREATE_PRODUCT_COLLECTION_ERROR' });
  }
};

export const updateCollection = async ({
  dispatch,
  token,
  id,
  collection
}: UpdateProductCollectionAction): Promise<Response<ProductCollection> | void> => {
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.updateCollection(id, collection);

    if (data.result) {
      Alert.success(`${data.result.name} updated`);
      dispatch({
        type: 'PRODUCT_COLLECTION_UPDATED',
        payload: data.result
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'UPDATE_COLLECTION_ERROR' });
    }

    return data;
  } catch {
    Alert.error('Error while trying to update the collection');
    dispatch({ type: 'UPDATE_COLLECTION_ERROR' });
  }
};

export const deleteCollection = async ({
  dispatch,
  token,
  collectionId,
  categoryId
}: DeleteProductCollectionAction): Promise<boolean> => {
  let result = false;
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.deleteProductCollection(collectionId);

    if (data.result) {
      Alert.success('Type deleted');
      dispatch({
        type: 'PRODUCT_COLLECTION_DELETED',
        payload: { collectionId, categoryId }
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'PRODUCT_COLLECTION_DELETED_ERROR' });
    }

    result = !!data.result;
  } catch {
    Alert.error('Error while trying to delete the category');
    dispatch({ type: 'PRODUCT_COLLECTION_DELETED_ERROR' });
  }

  return Promise.resolve(result);
};

export const removeProductFromCollection = async ({
  collectionId,
  dispatch,
  productId,
  token
}: RemoveProductFromCollectionAction) => {
  let result = false;
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.removeProductFromCollection(productId, collectionId);

    if (data.result) {
      dispatch({
        type: 'REMOVED_PRODUCT_FROM_COLLECTION',
        payload: { collectionId, productId }
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'REMOVE_PRODUCT_FROM_COLLECTION_ERROR' });
    }

    result = !!data.result;
  } catch {
    Alert.error('Error while trying to remove productFromCollection');
    dispatch({ type: 'REMOVE_PRODUCT_FROM_COLLECTION_ERROR' });
  }

  return Promise.resolve(result);
};

export const addProductToCollection = async ({
  collectionId,
  dispatch,
  productId,
  token
}: AddProductToCollectionAction): Promise<Response<ProductCollection> | void> => {
  try {
    const collectionService = new CollectionService(token);
    const data = await collectionService.addProductToCollection(productId, collectionId);

    if (data.result) {
      dispatch({
        type: 'ADD_PRODUCT_TO_COLLECTION',
        payload: data.result
      });
    }

    if (data.errorMessage) {
      Alert.error(data.errorMessage);
      dispatch({ type: 'ADD_PRODUCT_TO_COLLECTION_ERROR' });
    }

    return Promise.resolve(data);
  } catch {
    Alert.error('Error while trying to remove productFromCollection');
    dispatch({ type: 'ADD_PRODUCT_TO_COLLECTION_ERROR' });
  }
};

import { toastr } from 'react-redux-toastr';

const options = { timeOut: 5000 };

class Alert {
  static success(message: string) {
    toastr.success('', message, options);
  }

  static error(message: string) {
    toastr.error('', message, options);
  }

  static info(message: string) {
    toastr.info('', message, options);
  }
}

export default Alert;

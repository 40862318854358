import { combineReducers } from 'redux';

import themeReducer from '@arrive/redux/reducers/themeReducer';
import authReducer from '@arrive/redux/reducers/authReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  toastr: toastrReducer
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;

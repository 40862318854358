import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { dashboardLayoutRoutes, presentationLayoutRoutes } from './index';

import DashboardLayout from '@arrive/layouts/Dashboard';
import AuthLayout from '@arrive/layouts/Auth';
import Page404 from '@arrive/pages/auth/Page404';
import { RouteType } from '@arrive/types/routes';
import { ElementType, Fragment } from 'react';

const childRoutes = (Layout: ElementType, routes: Array<RouteType>) =>
  routes.map(({ component: Component, guard, children, path }, index: number) => {
    const Guard = guard || Fragment;

    return children ? (
      children.map((element, index: number) => {
        const Guard = element.guard || Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props: RouteComponentProps) => (
              <Guard>
                <Layout>
                  <element.component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {/*{childRoutes(DashboardLayout, protectedRoutes)}*/}
      {/*{childRoutes(AuthLayout, authLayoutRoutes)}*/}
      {childRoutes(DashboardLayout, presentationLayoutRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;

interface Auth0Config {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
}

const config: Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENTID || '',
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI || '',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || ''
};

export default config;

import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';
import ReduxToastr from 'react-redux-toastr';
import { ThemeProvider } from 'styled-components/macro';
import { create, InsertionPoint, Jss } from 'jss';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset, StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import createTheme from './theme';
import Routes from './routes/Routes';
import { AppStateType } from './redux/reducers';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './components/Loader';
import { Grid } from '@material-ui/core';
import { CloudinaryContext } from 'cloudinary-react';
import cloudinaryConfig from './config/cloudinaryConfig';
import { AdminStateProvider } from './contexts/AdminContext';
import { CollectionContextProvider } from './contexts/Collection/context';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { SnackbarProvider, Snackbars } from '@arrive/components/Arrive/Snackbar';

const jss: Jss = create({
  ...(jssPreset() as any),
  insertionPoint: document.getElementById('jss-insertion-point')! as InsertionPoint
});

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);
  const { isAuthenticated, isLoading, error, loginWithRedirect, user } = useAuth0();

  let comp = null;

  if (isLoading) {
    comp = (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}>
        <Loader />
      </Grid>
    );
  } else if (error) {
    comp = <div>{error}</div>;
  } else if (!isAuthenticated || !user) {
    loginWithRedirect();
  } else if (!user['https://schemas.arriveoutdoors.com/roles'].includes('Admin')) {
    window.location.href = 'https://arriveoutdoors.com';
  } else {
    comp = (
      <React.Fragment>
        <Helmet titleTemplate="%s | AO Admin" defaultTitle="AO Admin" />
        <ReduxToastr
          timeOut={1500}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <SnackbarProvider>
          <StylesProvider jss={jss as any}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <CloudinaryContext {...cloudinaryConfig}>
                    <AdminStateProvider>
                      <CollectionContextProvider>
                        <Routes />
                        <Snackbars />
                      </CollectionContextProvider>
                    </AdminStateProvider>
                  </CloudinaryContext>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </SnackbarProvider>
      </React.Fragment>
    );
  }

  return comp;
}

export default App;

import axios from 'axios';
import { Partner } from '@arrive/types/partner';

class PartnerService {
  constructor(token: string) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.baseURL = process.env.REACT_APP_COMMERCE_API_URL;
  }

  async GetPartners(): Promise<Partner[]> {
    const response = await axios({
      url: '/admin/partner'
    });
    return response.data;
  }
}

export default PartnerService;
